import {
  Grid,
  Typography,
  Box,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  SelectChangeEvent,
  Autocomplete,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Formik, Form, Field } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { yup, debounce } from "../../lib";
import {
  adminActions,
  adminSelectors,
  Raffle,
  Recording,
} from "../../state";
import { InputField, DatePicker, } from "../inputs/InputField";
import { TextField } from "formik-mui";
import { format } from "date-fns";
interface Props {
  closeModal: () => void;

}



const ToggleButtonGroupStyled = styled(ToggleButtonGroup)(({ theme }) => ({
  marginTop: 20,
  height: 56,
  "& .MuiButtonBase-root.Mui-selected": {
    background: theme.palette.primary.main,
  },
  "& .MuiButtonBase-root.Mui-selected:hover": {
    background: theme.palette.primary.main,
  },
  "& .Mui-selected>.MuiTypography-root": {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
}));


export const RaffleForm = ({ closeModal }: Props) => {


  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [recSearch, setRecSearch] = useState<string>("");

  const [editMode, setEditMode] = useState(false);

  const validationSchema = yup.object({
    ticket_price: yup.number().required('Points needed is required'),
  });

  const _initialValues = {
    name: "",
    ticket_price: 0,
    weekly: false,
    auto_entry: false,
    start_date: format(new Date(), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
    recording_id: null,
  } as Partial<Raffle>;

  const [initialValues, setInitialValues] = useState(_initialValues);

  const dispatch = useDispatch();
  const raffle = useSelector(adminSelectors.raffle);
  useEffect(() => {
    (async () => {

      if (raffle.id) {
        setInitialValues({ ...raffle });
        setEditMode(true);
      } else {
        setEditMode(false);
      }
    })();
  }, [dispatch, raffle]);

  useEffect(() => {
    (async () => {
      const escapedSearch = recSearch.replace("_", "\\_");
      const s = recSearch !== "" ? `q=${escapedSearch}` : raffle.recording_id ? `q=${raffle.recording_id}` : "field=id";
      const local = (await dispatch(
        adminActions.getRecordingsLocal(`${s}&range=0&range=100&sort=desc`),
      )) as unknown as Recording[];
      setRecordings(local);
    })();
  }, [recSearch, raffle.recording_id]);

  const search = (e: SelectChangeEvent) => {
    const {
      target: { value },
    } = e;
    setRecSearch(value.trim());
  };

  const onChange = useCallback(
    debounce(search, 500),
    []
  );



  const handleSubmit = (values, { setSubmitting }) => {

    if (editMode) {
      dispatch(adminActions.updateRaffle(raffle.id, values));
    } else {
      dispatch(adminActions.createRaffle(values));
    }
    setSubmitting(false);
    closeModal();
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}

    >
      {({ isSubmitting, setFieldValue, values, errors, touched }) => (
        <Form>
          <Grid
            sx={{
              width: "100%",
              border: "1px solid #E4E7EB",
              borderRadius: 2,
              m: 1,
              mt: 2,
              p: 2,
            }}
            display={"flex"}
            flexDirection="column"
            container
          >
            <Typography variant="h6">
              {editMode ? "Edit Raffle" : "Add Raffle"}
            </Typography>
            <Grid item xs={12} sx={{ width: "100%" }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={2}
              >
                <InputField label={"Name"} name={"name"} />

                <FormControlLabel
                  control={
                    <Checkbox checked={Boolean(values.auto_entry)} />
                  }
                  onChange={(e, v) => {
                    setFieldValue("auto_entry", v);
                  }}
                  label="Auto Entry"
                  sx={{ width: "100%" }}
                />

              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                gap={2}
              >
                <InputField label={"Points Needed"} name={"ticket_price"} />
                {values.auto_entry === true && (
                  <ToggleButtonGroupStyled
                    fullWidth
                    value={values.weekly}
                    exclusive
                    onChange={(_e, v) => {
                      if (v == undefined) return;
                      setFieldValue("weekly", v)
                    }}
                  >
                    <ToggleButton value={false}>
                      <Typography>Custom</Typography>
                    </ToggleButton>
                    <ToggleButton value={true}>
                      <Typography>Weekly</Typography>
                    </ToggleButton>
                  </ToggleButtonGroupStyled>
                )}
              </Box>
            </Grid>
            {values.auto_entry === true && values.weekly === false && (

              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={2}
                >
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="Start Date"
                    fieldName="start_date"
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                  />
                  <DatePicker
                    sx={{ width: "100%" }}
                    label="End Date"
                    fieldName="end_date"
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                  />


                </Box>
              </Grid>
            )}
            {values.auto_entry === true && (
              <Grid>

                <Autocomplete
                  options={recordings}
                  value={recordings.find(r => r.id === values?.recording_id) ?? null}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  onChange={(e, v) => {
                    setFieldValue("recording_id", v?.id);
                  }}
                  getOptionLabel={(option) => option?.label ?? ""}
                  renderInput={(params) => (
                    <Field
                      {...params}
                      component={TextField}
                      label="Recording"
                      name="recording_id"
                      onChange={onChange}
                    />
                  )}
                />


              </Grid>
            )}
            <Grid item xs={12}>

            </Grid>
          </Grid>
          <Box display={"flex"} width={"100%"} justifyContent={"flex-end"}>
            <Button
              sx={{ mt: 3, ml: 3, mr: 0 }}
              color="primary"
              variant="contained"
              type="submit"
              disabled={isSubmitting}
            >
              Save Raffle
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
