import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  TextField,
  Typography,
  SelectChangeEvent
} from "@mui/material";
import { Formik, Form, FormikProps } from "formik";
import { InputField, DatePicker } from "../inputs/InputField";
import { debounce, deepCopy, yup } from "../../lib";
import {
  adminActions,
  useDispatch,
} from "../../state";
import { useCallback, useEffect, useRef, useState } from "react";
import AdminPages from "../../pages/admin";
import { useNavigate, useParams } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableCard } from "../DraggableCard";
import { arrayMoveImmutable } from "array-move";
import { format } from "date-fns";
import { Recording } from "../../state/types";

const validationSchema = yup.object({
  name: yup.string().required("  Name is required"),
  serial_id: yup.number().required("Internal serial id is required"),
});

interface SerialRecording {
  id: number;
  name: string;
  serial_id: number;
  recording_id: number;
  chapter: number;
  go_live: string;
  active?: any;
  recording: Recording;
}

interface FormikValues {
  name: string;
  serial_id: number;
  recordings: SerialRecording[];
}

export const SerialRecordingForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef<FormikProps<any> | null>(null);

  const [initialValues, setInitialValues] = useState<
    undefined | FormikValues
  >();
  const params = useParams();
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [recSearch, setRecSearch] = useState<string>("");
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    dispatch(adminActions.getSerials());
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      const escapedSearch = recSearch.replace("_", "\\_");
      const s = recSearch !== "" ? `q=${escapedSearch}` : "field=id";
      const local = (await dispatch(
        adminActions.getRecordingsLocal(`${s}&range=0&range=100&sort=desc`),
      )) as unknown as Recording[];
      setRecordings(local);
    })();
  }, [recSearch]);

  const search = (e: SelectChangeEvent) => {
    const {
      target: { value },
    } = e;
    setRecSearch(value.trim());
  };
  useEffect(() => {
    (async () => {
      if (params?.id) {
        const sr: any = await dispatch(
          adminActions.getSerialRecordingsBySerial(params.id),
        );
        const s: any = await dispatch(
          adminActions.getSerialById(Number(params.id)),
        );

        if (sr && s) {
          setEditMode(true);
          setInitialValues({
            name: s.name ?? "",
            serial_id: Number(s.id),
            recordings: [...sr.filter((s) => !!s.recording_id)],
          });
        }
      } else {
        setInitialValues({
          name: "",
          serial_id: -1,
          recordings: [],
        });
      }
    })();
  }, [dispatch]);

  const handleSubmit = async (values, { setSubmitting }) => {
    if (editMode) {
      dispatch(adminActions.updateSerialRecording(params.id, values));
    } else {
      dispatch(adminActions.createSerialRecording(values));
      navigate(AdminPages.serials.path);
    }
    setSubmitting(false);
  };

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    const form = formRef.current;
    const _recordings = formRef.current?.values?.recordings ?? [];
    const newRecordingsArray: any[] = arrayMoveImmutable(
      deepCopy(_recordings),
      dragIndex,
      hoverIndex,
    );
    const fixedChapters = newRecordingsArray.map((c, idx) => ({
      ...c,
      chapter: idx + 1,
    }));
    form?.setFieldValue("recordings", fixedChapters);
  }, []);

  if (!initialValues) {
    return null;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={formRef}
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        errors,
        touched

      }) => (
        <Form>
          <Grid sx={{}} alignItems={"center"} container spacing={4}>
            <Grid sx={{ width: "100%" }} item xs={12} md={12}>
              <Grid item xs={6} sx={{ width: "100%" }}>
                <InputField label={"Name"} name={"name"} />
              </Grid>

              {!!recordings.length && (
                <Autocomplete
                  freeSolo
                  filterOptions={(o, _s) => o}
                  disableCloseOnSelect
                  multiple
                  filterSelectedOptions
                  options={recordings}
                  value={values.recordings.map((r) => r.recording)}
                  isOptionEqualToValue={(option, value) =>
                    !!option?.id && option.id === value?.id
                  }
                  onChange={(e, v) => {
                    //Figure out which is the new recordingID
                    const currRecordingIDs = values.recordings.map(
                      (r) => r.recording_id,
                    );
                    const currentRecordings = values.recordings;
                    const updatedRecordingIds = v.map((r) => r.id);
                    if (v.length > currentRecordings.length) {
                      //If Added
                      const missingRecording = v.find(
                        ({ id }) => !currRecordingIDs.includes(id),
                      );
                      if (!missingRecording) {
                        throw new Error("Missing missingRecording");
                      }
                      const newRec = {
                        active: 1,
                        chapter: currentRecordings.length + 1,
                        go_live: format(new Date(), "yyyy-MM-dd"),
                        id: undefined,
                        name: missingRecording?.label ?? "",
                        recording: missingRecording,
                        recording_id: missingRecording?.id,
                        serial_id: params.id,
                      };
                      const updatedRecordings = [...currentRecordings, newRec];
                      setFieldValue("recordings", updatedRecordings);
                    } else if (v.length === currentRecordings.length) {
                      console.error(
                        "no updated: Not sure how that make sense?",
                      );
                    } else if (v.length < currentRecordings.length) {
                      //One item removed
                      const removedRecordingId = currRecordingIDs.find(
                        (id) => !updatedRecordingIds.includes(id),
                      );
                      const updatedRecordings = currentRecordings.filter(
                        (r) => r.recording_id !== removedRecordingId,
                      );
                      setFieldValue("recordings", updatedRecordings);
                    } else {
                      throw new Error(
                        "Ended up in else block Autocomplete onChange ",
                      );
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Recordings" onChange={debounce(search, 500)} />
                  )}
                />
              )}
              {!!values.recordings?.length && (
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel>Recording orders</InputLabel>
                  <Box sx={{ border: "2px solid #000", padding: 2, mt: 6 }}>
                    <DndProvider backend={HTML5Backend}>
                      {values.recordings.map((r, idx: number) => (
                        <Grid key={idx} container spacing={2}>
                          <Grid item md={1}>
                            <Typography> Chapter : {idx + 1}</Typography>
                          </Grid>
                          <Grid item md={6}>
                            <DraggableCard
                              index={idx}
                              id={r.id}
                              text={r.recording?.label ?? "Missing"}
                              moveCard={moveCard}
                            />
                          </Grid>
                          <Grid item md={2}>
                            {!!r.recording?.full_url && (
                              <audio
                                style={{ maxWidth: 180 }}
                                preload="none"
                                controls
                                src={r.recording.full_url}
                              />
                            )}
                          </Grid>
                          <Grid item md={3}>
                            <DatePicker

                              label="Go Live"
                              fieldName={`recordings.${idx}.go_live`}
                              values={values}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                            />
                          </Grid>
                        </Grid>
                      ))}
                    </DndProvider>
                  </Box>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Button
            sx={{ mt: 3, ml: 0 }}
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitting}
          >
            Save
          </Button>
        </Form>
      )}
    </Formik>
  );
};
